import React from 'react'
import { Row, Col, Card } from 'reactstrap'

const data = [
  {
    name: 'Richard H. Pearl',
    title: 'MD FACS FAAP FRCS© Professor of Surgery',
    quote:
      'SafeStart has given me and my surgical team the confidence that we will be doing all we can to reduce the possibility of a Never Event. My clinical staff and I love SafeStart. By using SafeStart we all believe we are implementing the best practices in surgical safety and patient engagement.'
  },
  {
    name: 'Clark Shierle',
    title: 'MD, PhD, FACS, Northwestern Plastics',
    quote:
      'My plastic surgery patients love the fact that we engage them in the safety process, and they found the SafeStart app incredibly easy to use.  I really feel like with SafeStart I will never have to worry about a Never Event.'
  },
  {
    name: 'Richard H. Pearl',
    title: 'MD FACS FAAP FRCS© Professor of Surgery',
    quote:
      'SafeStart is a great tool to involve my pediatric surgical patients and their parents in the surgical safety process.  Patient engagement on the patient portal is almost 100%.'
  }
]

export default () => {
  const testimonials = data.map((person, index) => {
    return (
      <Col
        key={index}
        xs="12"
        sm="6"
        md="6"
        lg="4"
        style={{ marginBottom: 20 }}
      >
        <Card body>
          <p>{person.quote}</p>
          <p>
            <strong>{person.name}</strong>
            <span> {person.title}</span>
          </p>
        </Card>
      </Col>
    )
  })

  return <Row>{testimonials}</Row>
}
