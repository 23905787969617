import React from 'react'
import { Container, Row, Col, Card, CardTitle, Button } from 'reactstrap'
import ScrollAnimation from 'react-animate-on-scroll'

import options from '../img/options.png'
import heart from '../img/heart.png'
import ambulance from '../img/ambulance.png'
import engage from '../img/engage.png'
import AppLinks from '../components/AppLinks'

import Testimonials from '../components/Testimonials'

import Layout from '../components/Layout'

export default class IndexPage extends React.Component {
  render() {
    return (
      <Layout>
        <section className="hero-section-front">
          <div className="full-width-image-container hero">
            <Container>
              <Row>
                <Col xs="12" md="6">
                  <div className="front-content">
                    <h2
                      className="has-text-weight-bold is-size-2 space-below"
                      style={{
                        color: 'white'
                      }}
                    >
                      We Help You Prevent Never Events
                    </h2>
                    <p>
                      SafeStart is a surgical safety solution that
                      <strong> eliminates Never Events</strong>, reduces delays
                      in the operating room, and increases patient engagement.
                    </p>
                    <Button
                      size="lg"
                      color="primary"
                      href="/contact/"
                      className="space-below"
                    >
                      Request a Demo
                    </Button>
                  </div>
                </Col>

                <Col xs="12" md="6">
                  <ScrollAnimation animateIn="fadeIn" animateOnce={true}>
                    <div className="front-video">
                      <iframe
                        src="https://player.vimeo.com/video/224414725"
                        width="640"
                        height="360"
                        title="SafeStart Vimeo Video Embed"
                        //prettier-ignore
                        frameBorder="0"
                        webkitallowfullscreen="true"
                        mozallowfullscreen="true"
                        allowFullScreen="true"
                      />
                    </div>
                  </ScrollAnimation>
                </Col>
              </Row>
            </Container>
          </div>
        </section>

        <section className="padded-section bg-light">
          <Container>
            <ScrollAnimation animateIn="fadeIn" animateOnce={true}>
              <h1>Created by a surgeon with a passion for safety</h1>
              <p>
                SafeStart uses mobile, cloud-based, HIPAA compliant technology
                to create an enhanced patient safety record complete with
                surgeon annotated photos, important clinical documents,
                allergies, consent forms, and more.
              </p>
              <p>
                Multiple safety record reviews and sign-offs by clinical staff,
                and the patient, allow for procedures to start safely and on
                time.
              </p>
            </ScrollAnimation>
          </Container>
        </section>

        <section className="padded-section bg-primary">
          <Container>
            <Row>
              <Col sm="12" md="4" className="space-below">
                <ScrollAnimation animateIn="fadeInLeft" animateOnce={true}>
                  <img className="img-fluid" src={options} alt="Hero" />
                </ScrollAnimation>
              </Col>
              <Col sm="12" md="8" className="space-below">
                <h3>Available on Major Platforms</h3>
                <p>
                  Use SafeStart on iOS (iPad and iPhone) as well as Android
                  (phones and tablets) to enter patient information in the
                  pre-op area and then perform a timeout before starting
                  surgery.
                </p>
                <AppLinks />
              </Col>
            </Row>
          </Container>
        </section>

        <section className="padded-section bg-light">
          <Container>
            <ScrollAnimation animateIn="fadeInUp" animateOnce={true}>
              <h1 className="text-center">Why SafeStart?</h1>
              <Row>
                <Col xs="12" sm="6" lg="4" className="space-below">
                  <Card body className="text-center">
                    <div className="space-below">
                      <img src={heart} alt="Heart Icon" style={{ width: 64 }} />
                    </div>
                    <CardTitle>Eliminate Never Events</CardTitle>
                    <div className="card-content">
                      <p>
                        Never Events are unacceptable and cause great harm to
                        patients as well as negatively impacting doctors,
                        nurses, and their facilities.
                      </p>
                      <p>
                        SafeStart is designed to eliminate wrong patient, wrong
                        procedure, and wrong site Never Events by using enhanced
                        and vetted clinical information.
                      </p>
                    </div>
                  </Card>
                </Col>

                <Col xs="12" sm="6" lg="4" className="space-below">
                  <Card body className="text-center">
                    <div className="space-below">
                      <img src={engage} alt="SafeStart" style={{ width: 64 }} />
                    </div>
                    <CardTitle>Increase Patient Engagement</CardTitle>
                    <div>
                      <p>
                        Engaged patients lead to improved health outcomes,
                        better patient care, and lower costs.
                      </p>
                      <p>
                        SafeStart is designed to include patients and their
                        families in the safety process.
                      </p>
                    </div>
                  </Card>
                </Col>

                <Col xs="12" sm="6" lg="4" className="space-below">
                  <Card body className="text-center">
                    <div className="space-below">
                      <img
                        src={ambulance}
                        alt="SafeStart"
                        style={{ width: 64 }}
                      />
                    </div>
                    <CardTitle>Reduce Delays and Cancellations</CardTitle>
                    <div className="card-content">
                      <p>
                        Delays and cancellations in the operating room disrupt
                        patient flow, increase operational costs, and raise
                        patient anxiety.
                      </p>
                      <p>
                        SafeStart's comprehensive patient safety document set is
                        vetted multiple times before the patient arrives at the
                        pre-operative area - streamlining the safety process so
                        procedures can start on time.
                      </p>
                    </div>
                  </Card>
                </Col>
              </Row>
            </ScrollAnimation>
          </Container>
        </section>

        <section className="padded-section">
          <Container>
            <ScrollAnimation animateIn="fadeInUp" animateOnce={true}>
              <h1 className="text-center">Testimonials</h1>
              <Testimonials />
            </ScrollAnimation>
          </Container>
        </section>
      </Layout>
    )
  }
}
